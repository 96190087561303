
import { defineComponent, reactive, ref, PropType, watch, computed } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useNotify } from '@/store'

import { isRequired } from '@/utils/validation'
import { handleError } from '@/utils/handleError'
import { isOfType } from '@/utils/types/isOfType'

import { useCreateProfile, useUpdateProfile } from '@/api/profile'

import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { ProfileInput, ProfileOutput, PROFILE_TYPE } from '@/api/types/profile'

export default defineComponent({
  name: 'AddEditProfileDialog',
  components: {
    CommonAddEditDialog,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    profileToEdit: {
      type: Object as PropType<ProfileOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const isEditMode = computed(() => Boolean(props.profileToEdit))

    const FORM_FIELDS: FormField[] = [
      {
        value: 'nr',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [],
      },
      {
        value: 'name',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('baseData.profiles.form.name') as string)],
      },
      {
        value: 'description',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'type',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => TYPE),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('baseData.profiles.form.type.title') as string)],
      },
    ]

    const TYPE = [
      {
        value: PROFILE_TYPE.TENDERING,
        text: root.$t('baseData.profiles.form.type.tendering'),
      },
      {
        value: PROFILE_TYPE.ASSIGNMENT,
        text: root.$t('baseData.profiles.form.type.assignment'),
      },
    ]

    const form = ref<ProfileInput | ProfileOutput>(
      isEditMode.value ? cloneDeep(props.profileToEdit) : ({} as ProfileInput)
    )

    watch(
      () => props.profileToEdit,
      () => {
        if (props.profileToEdit) {
          form.value = cloneDeep(props.profileToEdit)
        }
      },
      {
        immediate: true,
      }
    )

    function close(): void {
      emit('added-edited')
      emit('close')
    }

    const { createProfile, isLoading: isLoadingCreateProfile } = useCreateProfile()

    async function onAdd(): Promise<void> {
      if (!isOfType<ProfileOutput>(form.value, 'id')) {
        try {
          await createProfile(form.value)

          addNotification({
            text: root.$t(`baseData.profiles.create.message`) as string,
            type: 'success',
          })

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateProfile, isLoading: isLoadingUpdateProfile } = useUpdateProfile()

    async function onEdit(): Promise<void> {
      if (isOfType<ProfileOutput>(form.value, 'id')) {
        try {
          await updateProfile(form.value.id, form.value)

          addNotification({
            text: root.$t(`baseData.profiles.edit.message`) as string,
            type: 'success',
          })

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditProfile = computed(() => isLoadingCreateProfile.value || isLoadingUpdateProfile.value)

    return reactive({
      constants: {
        FORM_FIELDS,
        FORM_FIELDS_ENUM,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditProfile,
      },
      listeners: {
        close,

        onAdd,

        onEdit,
      },
    })
  },
})
